import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/ProjectFinder.vue'),
  },
  {
    path: '/project/:project_id/',
    name: 'Project',
    component: () => import('../views/SozaiFinder.vue'),
  },
  {
    path: '/project/:project_id/search/',
    name: 'Search',
    component: () => import('../views/Search.vue'),
  },
  {
    path: '/project/:project_id/watchlist/',
    name: 'WatchList',
    component: () => import('../views/WatchListFinder.vue'),
  },
  {
    path: '/project/:project_id/result/',
    name: 'Result',
    component: () => import('../views/Result.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
