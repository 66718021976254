import { inject, ref, toRefs, unref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'

export default class Mixins {
  route: any
  router: any
  store: any
  gapi: any
  conf: any

  constructor() {
    this.route = useRoute()
    this.router = useRouter()
    this.store = useStore()
    this.gapi = inject('$gapi')
    this.conf = inject('$conf')
  }

  /*--------------------------------*/
  // 変換系
  /*--------------------------------*/
  hello(): any {
    console.log('hello')
  }
  getDuplicateObject(object: any): any {
    const duplicate = Object.assign({}, JSON.parse(JSON.stringify(object)))
    return duplicate
  }
  getDuplicateArray(array: []): any {
    const duplicate = Object.assign([], JSON.parse(JSON.stringify(array)))
    return duplicate
  }
  getFormatedDate(timecode: any, onlyDate: boolean): any {
    if (typeof timecode === 'string') {
      timecode = timecode.replace(/Z$/g, '')
    } else if (typeof timecode === 'number') {
      timecode -= (9 * 60 * 60 * 1000)
    }
    const date = new Date(timecode)
    const y = date.getFullYear().toString()
    const m = (date.getMonth() + 1).toString().padStart(2, '0')
    const d = date.getDate().toString().padStart(2, '0')
    const hh = date.getHours().toString().padStart(2, '0')
    const mm = date.getMinutes().toString().padStart(2, '0')
    const ss = date.getSeconds().toString().padStart(2, '0')
    const msec = date.getMilliseconds().toString()
    if(onlyDate) {
      return `${y}/${m}/${d}`
    } else {
      return `${y}/${m}/${d} ${hh}:${mm}:${ss}`
    }
  }
  getFormatedTimeCode(timecode: any): any {
    if (typeof timecode === 'string') {
      timecode = timecode.replace(/Z$/g, '')
    } else if (typeof timecode === 'number') {
      timecode -= (9 * 60 * 60 * 1000)
    }
    const date = new Date(timecode)
    const y = date.getFullYear().toString()
    const m = (date.getMonth() + 1).toString().padStart(2, '0')
    const d = date.getDate().toString().padStart(2, '0')
    const hh = date.getHours().toString().padStart(2, '0')
    const mm = date.getMinutes().toString().padStart(2, '0')
    const ss = date.getSeconds().toString().padStart(2, '0')
    const msec = date.getMilliseconds().toString()
    return `${hh}:${mm}:${ss}`
  }
  sec2hhmmss(duration: number, decimal: boolean): any {
    // duration = parseInt(duration)
    const hh = Math.floor(duration / (60 * 60 * 1000)).toString().padStart(2, '0')
    const mm = Math.floor((duration % (60 * 60 * 1000)) / (60 * 1000)).toString().padStart(2, '0')
    const ss = Math.floor((duration % (60 * 1000)) / 1000).toString().padStart(2, '0')
    const msec = Math.floor(duration % 1000).toString().padStart(3, '0')
    if (decimal) {
      return `${hh}:${mm}:${ss}.${msec}`
    } else {
      return `${hh}:${mm}:${ss}`
    }
  }
  hhmmss2sec(duration: string): any {
    if (duration) {
      const arr = duration.split(':')
      return parseInt(arr[0]) * 3600 + parseInt(arr[1]) * 60 + parseInt(arr[2])
    } else {
      return '00:00:00'
    }
  }
  getQuery(): any {
    const query: any = {}
    location.search
      .substring(1)
      .split('&')
      .forEach((v) => {
        const s: string[] = v.split('=')
        query[s[0]] = s[1]
      })
    return query
  }
  matchRegExp(targetString: string, queryString: string): any {
    // AND検索対応
    // 文頭文末の半角・全角スペースをトリム
    // 文中の半角・全角スペースをTABに置き換え
    // TAB区切りで配列化
    const queries = queryString
      .replace(/^[\u{20}\u{3000}]+|[\u{20}\u{3000}]+$/gu, '')
      .replace(/[\u{20}\u{3000}]+/gu, '\t')
      .split('\t')
    // query配列のすべてがtrueならtrue
    // 大文字小文字の区別をしない
    return queries.every((q: any) => {
      const query = `^(?=.*${q}).*$`
      const reg = new RegExp(query, 'gi')
      return targetString.match(reg)?.length ? true : false
    })
  }

  /*--------------------------------*/
  // api_testを移植
  /*--------------------------------*/
  // 吾郎さん式の共通関数
  async api(url: string, data: any, option: any): Promise<any> {
    this.store.commit('setPending', true)
    option = option || {}
    const headers = option.headers || {}
    const files = option.files || []
    data = data || {}
    const kaotan_sid = this.store.getters.kaotanSid
    data['kaotan_sid'] = kaotan_sid
    let params: any = null
    if (headers['content-type'] == 'multipart/form-data') {
      params = new FormData()
      for (let i = 0; i < files.length; i++) {
        params.append('files[]', files[i])
      }
      for (const k in data) {
        params.append(k, JSON.stringify(data[k]))
      }
    } else {
      params = data
    }
    return new Promise((res, rej) => {
      // console.groupCollapsed(url)
      // console.log('API POST:' + this.conf.APIHOST + url)
      axios
        .post(this.conf.APIHOST + url, params)
        .then((r: any) => {
          // console.log(r.data)
          // res(r.data)
          console.groupCollapsed(this.conf.APIHOST + url, params)
          console.log(r.data)
          console.groupEnd()

          this.store.commit('setPending', false)
          // 返却値にresultがあったりなかったり
          if (url === '/api/islogin') {
            res(r.data)
          } else {
            switch (r.data.result) {
              case 'success': {
                // console.log(r.data)
                res(r.data)
                // return r.data
                break
              }
              case 'error': {
                alert('エラーが発生しました\nトップ画面へ戻ります')
                // this.signOut()
                this.router.push('/')
                rej()
                // return false
                break
              }
            }
          }
        })
        .catch((e: any) => {
          console.log(e)
          this.store.commit('setPending', false)
          alert(e)
        })
      // console.groupEnd()
    })
  }
  // APIのresponseを返却する共通関数
  async api2(url: string, data: any, option: any): Promise<any> { // API呼び出し共通
    this.store.commit('setPending', true)
    option = option || {}
    data = data || {}
    const kaotan_sid = this.store.getters.kaotanSid
    data['kaotan_sid'] = kaotan_sid

    const response: any = await axios
      .post(this.conf.APIHOST + url, data, option)
      .then(async (r: any) => {
        console.groupCollapsed(this.conf.APIHOST + url, data)
        console.log(r.data)
        console.groupEnd()
        switch (r.data.result) {
          case 'success': {
            // console.log(r.data)
            // res(r.data)
            return r.data
          }
          case 'error': {
            alert('エラーが発生しました\nトップ画面へ戻ります')
            this.store.commit('clearPending')
            // this.signOut()
            this.router.push('/')
            return false
          }
        }
      })
      .catch((e: any) => {
        console.log(e)
        return null
      })
    this.store.commit('setPending', false)
    return response
  }
  async onlogin(user: any): Promise<any> {
    const guser = await this.api2('/api/profile', null, null)
    // console.log('onlogin', user, guser)
    // ユーザー名、アイコンのセット、poweruserはここでやる
    if (guser) {
      const profile = {
        kaotanuser: user,
        googleuser: guser,
      }
      this.store.commit('setProfile', profile)
      return true
    } else {
      console.log('api/profileがとれない')
      return false
    }
  }
  async islogin(): Promise<any> {
    this.api('/api/islogin', null, null)
  }
  logout(): any {
    // this.show_main = false
    this.api('/api/logout', null, null)
  }
  getaccesstoken(): any {
    this.api('/api/getaccesstoken', null, null)
  }
  getrefreshtoken(): any {
    this.api('/api/getrefreshtoken', null, null)
  }
  pickerapi(): any {
    this.api('/api/getaccesstoken', {}, null).then((data: any) => {
      const self: any = this.store.getters.profile.kaotanuser
      const oauthToken = data.access_token
      const loadedStatus = this.store.getters.gapiStatus.picker
      console.groupCollapsed('status')
      console.log('self', self)
      console.log('loadedStatus:', loadedStatus)
      console.log('oauthToken:', oauthToken)
      console.groupEnd()
      if (loadedStatus && oauthToken) {
        const view = new google.picker.DocsView()
          .setParent('root')
          .setIncludeFolders(true)
          .setSelectFolderEnabled(true)
          .setEnableDrives(true)
          .setMimeTypes('video/mp4,video/quicktime,video/avi,application/octet-stream,application/vnd.google-apps.folder,video/mp2t,text/texmacs,video/x-ms-wmv')
        const picker = new google.picker.PickerBuilder()
          .addView(view)
          .setLocale('ja')
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          //.enableFeature(google.picker.Feature.MINE_ONLY)
          .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
          .setOAuthToken(oauthToken)
          //.setSize(1024,768)
          .enableFeature(google.picker.Feature.NAV_HIDDEN)
          .setCallback(async (res: any) => {
            console.log(res)
            if (self.poweruser) {
              const pickOnly = false
              try {
                let inputs = []
                // 選択されたものをリストアップ
                for (let i = 0; i < res.docs.length; i++) {
                  console.log(res.docs[i])
                  inputs.push({
                    file_id: res.docs[i].id,
                  })
                }
                if (!pickOnly) {
                  // 入力ファイルがフォルダかもしれないので展開する
                  await this.api('/api/extractfile', { inputs: inputs }, null)
                    .then((r: any) => {
                      // 展開後のファイル群をentryfileに渡す
                      inputs = [] // 一度空にする
                      for (let i = 0; i < r.outputs.length; i++) {
                        inputs.push({
                          file_id: r.outputs[i].file_id,
                          file_name: r.outputs[i].file_name,
                          parent_file_id: r.outputs[i].parent_file_id,
                          mimeType: r.outputs[i].mimeType,
                          size: r.outputs[i].size || null,
                        })
                      }
                    })
                    .catch((e: any) => {
                      alert(e)
                    })
                }
                this.store.commit('setDriveFiles', inputs)
              } catch (e: any) {
                // alert(e)
              }
              if (res.action === google.picker.Action.PICKED) {
                picker.dispose()
              } else if (res.action === google.picker.Action.CANCEL) {
                picker.dispose()
              }
            }
          })
          .build()
        picker.setVisible(true)
      }
    })
  }
  profile(): any {
    this.api('/api/profile', null, null)
  }
  signOut(): any {
    try {
      this.logout()
      this.store.commit('setKaotanSid', null) // 取得したkaotan_sidを破棄
      window.location.href = '/'
    } catch (error) {
      console.error(error)
    }
  }

  /*--------------------------------*/
  // API取得＆データ加工関数
  // 永田、吾郎さんの共同作業頻度が高まる場所なので
  // ここだけ別モジュールにするやも
  /*--------------------------------*/
  // プロジェクト一覧の取得
  async getListProject(): Promise<any> {
    const url = '/api/listproject'
    const data = {
      inputs: [
        {
          order_by: 'id',
          reverse: false,
        },
      ],
    }
    const response = await this.api2(url, data, null)
    response.projects = response.projects.map((project: any) => {
      project.share = [{email: project.user_id, role: 1}]
      return project
    })
    return response.projects
  }
  // プロジェクト詳細の取得
  async getProject(selected: any): Promise<any> {
    const url = '/api/getproject'
    const data = {
      inputs: [
        {
          project_id: selected.project_id,
        },
      ],
    }
    const response = await this.api2(url, data, null)
    return response.outputs[0]
  }
  // 取得した素材一覧をview用に加工
  getTreeSource(project: any): any {
    // プレーンなオブジェクトにする
    // ツリー化する際の配列置き場を作成する
    const folders: any[] = project.folders.map((folder: any) => {
      const folder_ = Object.assign({}, folder)
      folder_.parent_tbl.reverse()
      folder_.folders = []
      folder_.sources = []
      return folder_
    })

    // 構造をrootからの木構造に
    const tree: any[] = [
      {
        folder_name: 'root',
        parent_folder_id: null,
        folders: folders, // あとで加工
        sources: [],
        parent_tbl: [],
      },
    ]

    if (!folders.length) return tree

    // 並列なうちに素材を格納しておく
    project.sources.forEach((source: any) => {
      if (!source.folder_id) {
        // folder_idがない場合はプロジェクト直下
        tree[0].sources.push(source)
      } else {
        // そうでない場合はfolder配下のsourcesに格納
        tree[0].folders.forEach((folder: any) => {
          if(folder.folder_id === source.folder_id) {
            folder.sources.push(source)
          }
        })
      }
    })

    // フォルダリストをツリー化
    const list2Tree = () => {
      tree[0].folders = tree[0].folders.map((folder: any) => {
          if (folder.parent_folder_id !== null) {
            tree[0].folders.forEach((parent: any) => {
              if (parent.folder_id === folder.parent_folder_id) {
                parent.folders.push(folder)
                return
              }
            })
            return null
          } else {
            return folder
          }
        })
        .filter((folder: any) => folder)
      // 1階層目が親なしになるまで繰り返す
      if (tree[0].folders.every((folder: any) => folder.parent_folder_id !== null)) {
        list2Tree()
      }
    }
    // ツリー化関数発火
    list2Tree()

    // console.log(tree)
    return tree
  }
  // プロジェクト作成
  async createproject(formdata: any): Promise<any> {
    const url = '/api/createproject'
    const data = {
      inputs: [
        {
          project_name: formdata.project_name,
        },
      ],
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // プロジェクト更新
  async updateproject(formdata: any): Promise<any> {
    const url = '/api/updateproject'
    const data = {
      inputs: [
        {
          project_id: formdata.project_id,
          project_name: formdata.project_name,
        },
      ],
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // プロジェクト削除
  async deleteproject(formdata: any): Promise<any> {
    const url = '/api/deleteproject'
    const data = {
      inputs: formdata.map((project: any) => {
        return {
          project_id: project.project_id,
        }
      }),
    }
    const response = await this.api2(url, data, null)
    return response
  }
  async updatesharepro(formdata: any): Promise<any> {
    console.log(formdata)
    const url = '/api/updatesharepro'
    const data = {
      inputs: [formdata],
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // 素材追加
  async addSources(formdata: any): Promise<any> {
    const url = '/api/entryfile'
    const data = {
      inputs: formdata,
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // 素材更新
  async updatesource(formdata: any): Promise<any> {
    const url = '/api/updatesource'
    const data = {
      inputs: [
        {
          source_id: formdata.source_id,
          source_name: formdata.source_name,
          folder_id: formdata.folder_id,
        },
      ],
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // 素材移動
  async movesource(formdata: any): Promise<any> {
    const url = '/api/movesource'
    const data = {
      inputs: formdata.selects.map((source: any) => {
        return {
          source_id: source.source_id,
          project_id: formdata.direction.project_id,
          folder_id: formdata.direction.folder_id,
        }
      }),
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // 素材コピー
  async copysource(formdata: any): Promise<any> {
    const url = '/api/copysource'
    const data = {
      inputs: formdata.selects.map((source: any) => {
        return {
          from_source_id: source.source_id,
          source_name: source.source_name + 'のコピー',
          project_id: formdata.direction.project_id,
          folder_id: formdata.direction.folder_id,
        }
      }),
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // 素材削除
  async deletesource(formdata: any): Promise<any> {
    const url = '/api/deletesource'
    const data = {
      inputs: formdata.map((source: any) => {
        return {
          source_id: source.source_id,
        }
      }),
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // 素材ログ取得
  async getsourcelog(formdata: any): Promise<any> {
    const url = '/api/getsourcelog'
    const data = {
      inputs: formdata.map((source: any) => {
        return {
          source_id: source.source_id,
        }
      }),
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // 素材検索
  async searchsource(formdata: any, project_id: number): Promise<any> {
    //
    const url = '/api/search'
    const data = {
      inputs: [
        {
          project_id: project_id,
          search_text: formdata.cast,
          search_type: 'face',
        },
        {
          project_id: project_id,
          search_text: formdata.speech,
          search_type: 'speech',
        },
      ],
    }
    const response = await this.api2(url, data, null)
    return response.outputs[0].sources
  }
  // 素材再解析
  async research(formdata: any): Promise<any> {
    //
    console.log(formdata)
    const url = '/api/research'
    const data = {
      inputs: formdata.map((source: any) => {
        return {
          item_id: source.source_id,
        }
      }),
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // フォルダ作成
  async createfolder(formdata: any): Promise<any> {
    const url = '/api/createfolder'
    const data = {
      inputs: [
        {
          folder_name: formdata.folder_name,
          project_id: formdata.project_id,
          parent_folder_id: formdata.parent_folder_id,
        },
      ],
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // フォルダ更新
  async updatefolder(formdata: any): Promise<any> {
    const url = '/api/updatefolder'
    const data = {
      inputs: [
        {
          folder_id: formdata.folder_id,
          folder_name: formdata.folder_name,
          parent_folder_id: formdata.parent_folder_id,
        },
      ],
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // フォルダ移動
  async movefolder(formdata: any): Promise<any> {
    const url = '/api/movefolder'
    const data = {
      inputs: formdata.selects.map((folder: any) => {
        return {
          folder_id: folder.folder_id,
          project_id: formdata.direction.project_id,
          parent_folder_id: formdata.direction.folder_id,
        }
      }),
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // フォルダコピー
  async copyfolder(formdata: any): Promise<any> {
    const url = '/api/copyfolder'
    const data = {
      inputs: formdata.selects.map((folder: any) => {
        return {
          from_folder_id: folder.folder_id,
          folder_name: folder.folder_name + 'のコピー',
          project_id: formdata.direction.project_id,
          parent_folder_id: formdata.direction.folder_id,
        }
      }),
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // フォルダ削除
  async deletefolder(formdata: any): Promise<any> {
    const url = '/api/deletefolder'
    const data = {
      inputs: formdata.map((folder: any) => {
        return {
          folder_id: folder.folder_id,
        }
      }),
    }
    const response = await this.api2(url, data, null)
    return response
  }
  // デバイスから素材を追加
  async deviceupload(formdata: any, files: any, direction: any): Promise<any> {
    // console.log(files)
    const data: any = {
      inputs: formdata,
    }
    //
    // S3にアップロードするので
    // 署名つきURLを発行してもらう
    // その際に素材IDも返却されるが、この時点ではその素材IDで登録はされていない
    //
    // Array.from(files).forEach((file: any) => {
    //   data.inputs.push({
    //     filename: file.file_name,
    //     source_name: file.source_name,
    //   })
    // })
    return await this.api('/api/geturlforuploadvideo', data, null).then(async (data: any) => {
      if (data.outputs) {
        const response = await Promise.all(
          data.outputs.map(async (elem: any, i: number) => {
            return this.uploadFile(elem.upload_url, files[i])
              .then(async () => {
                // アップロードが完了したらデバイス用entryfileを呼ぶ
                // geturlforuploadvideoで返却された素材IDをここで指定する
                // 一応複数対応しているので一個でもinputs配列に入れて渡す
                return this.api(
                  '/api/entryfilefromdevice',
                  {
                    inputs: [
                      {
                        source_name: elem.source_name,
                        source_id: elem.source_id,
                        project_id: direction.project_id,
                        folder_id: direction.parent_folder_id,
                      },
                    ],
                  },
                  null
                )
                  .then((res: any) => {
                    // console.log(res)
                    return res
                  })
                  .catch((e: any) => {
                    console.log(e)
                    return e
                  })
              })
              .catch((e: any) => {
                console.log(e)
                return e
              })
          })
        )
          .then((res: any) => {
            // console.log('Promise.allのthen', res)
            return true
          })
          .catch((e: any) => {
            console.log(e)
            return false
          })
        // console.log('response', response)
        return response
      } else {
        return false
      }
    })
  }
  // プライベートウォッチリスト一覧の取得
  async getListPWatch(project_id: number): Promise<any> {
    const url = '/api/listpwatch'
    const data = {
      inputs: [
        {
          project_id: project_id,
        },
      ],
    }
    const response = await this.api2(url, data, null)
    return response.outputs[0].private_watch_list
  }
  // ファイルアップロード
  async uploadFile(url: string, file: any): Promise<any> {
    this.store.commit('setPending', true)
    const response = await axios
      .put(url, file, {
        headers: {
          'content-type': file.type,
        },
      })
      .then((r: any) => {
        console.log('uploadFile', r)
        return r
      })
      .catch((e: any) => {
        console.log(e)
        return e
      })
    this.store.commit('setPending', false)
    return response
  }
  // ウォッチリストの単体追加
  async addPWatch(formdata: any): Promise<any> {
    const url = '/api/addpwatch'
    const data = {
      inputs: [
        {
          filename: formdata.filename,
          url: formdata.url || null,
          name: formdata.name,
          other_name: formdata.other_name,
          project_id: formdata.project_id,
        },
      ],
    }
    const options = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      files: formdata.file ? [formdata.file] : null,
    }
    const response = await this.api(url, data, options)
      .then((res: any) => {
        // console.log('addpwatch', res) // {outputs: [], result: 'success'}
        if (res.result === 'success') {
          // const uploadResponse = this.uploadFile(res.outputs[0].upload_url, formdata.file)
          return true
        } else {
          return false
        }
      })
      .catch((e: any) => {
        console.log(e)
      })
    // return response.outputs[0].private_watch_list
    return response
  }
  // ウォッチリストの確認
  async checkPwatchImg(formdata: any): Promise<any> {
    const url = '/api/checkpwatchimg'
    const data = {
      inputs: [
        {
          filename: formdata.filename,
          url: formdata.url,
        },
      ],
    }
    const options = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      files: formdata.file ? [formdata.file] : null,
    }
    const response = await this.api(url, data, options)
      .then((res: any) => {
        // console.log('checkpwatchimg', res) // {outputs: [], result: 'success'}
        if (res.result === 'success') {
          return res.outputs[0] // inputsはとりあえず１つで
        } else {
          return false
        }
      })
      .catch((e: any) => {
        console.log(e)
      })
    return response
  }
  // ウォッチリストの更新
  async updatePWatch(formdata: any): Promise<any> {
    const url = '/api/updatepwatch'
    const data = {
      inputs: [
        {
          pwatch_id: formdata.pwatch_id,
          name: formdata.name,
          other_name: formdata.other_name,
        },
      ],
    }
    const response = await this.api(url, data, null)
      .then((res: any) => {
        if (res.result === 'success') {
          return true
        } else {
          return false
        }
      })
      .catch((e: any) => {
        console.log(e)
      })
    return response
  }
  // ウォッチリストのコピー
  async copyPWatch(formdata: any): Promise<any> {
    const url = '/api/copypwatch'
    const data = {
      inputs: formdata.selects.map((pwatch: any) => {
        return {
          pwatch_id: pwatch.pwatch_id,
          project_id: formdata.direction.project_id,
        }
      }),
    }
    const response = await this.api(url, data, null)
      .then((res: any) => {
        if (res.result === 'success') {
          return true
        } else {
          return false
        }
      })
      .catch((e: any) => {
        console.log(e)
      })
    return response
  }
  // ウォッチリストの削除
  async deletePWatch(formdata: any): Promise<any> {
    const url = '/api/deletepwatch'
    const data = {
      inputs: formdata.map((pwatch: any) => {
        return {
          pwatch_id: pwatch.pwatch_id,
        }
      }),
    }
    const response = await this.api(url, data, null)
      .then((res: any) => {
        if (res.result === 'success') {
          return true
        } else {
          return false
        }
      })
      .catch((e: any) => {
        console.log(e)
      })
    return response
  }
  // かおたんJSONの取得
  async getKaotanJSON(outputs: any): Promise<any> {
    const url = '/api/exporttl'
    const data = {
      inputs: Object.keys(outputs).map((key: any) => {
        return outputs[key]
      }),
    }
    const response = await this.api(url, data, null)
      .then((res: any) => {
        if (res.result === 'success') {
          res.outputs.forEach((elem: any) => {
            const blob: any = new Blob([JSON.stringify(elem.exportdata, null, '  ')])
            const url: any = URL.createObjectURL(blob)
            const a: any = document.createElement('a')
            a.download = elem.exportname + '.json'
            a.href = url
            a.click()
          })
          return true
        } else {
          return false
        }
      })
      .catch((e: any) => {
        console.log(e)
      })
    return response
  }
}
