<template>
  <component :is="layout" v-if="kaotan_sid && profile && created" />
  <auth v-else-if="created"></auth>
  <loading v-if="isPending"></loading>
</template>

<script lang="ts">
import { defineComponent, inject, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Auth from '@/views/Auth.vue'
import Loading from '@/components/Loading.vue'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'App',
  components: {
    auth: Auth,
    loading: Loading,
  },
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    // グローバル変数を読み込み
    const gapi: any = inject('$gapi')
    const conf: any = inject('$conf')
    // let isSignedIn = false

    const mixins = new Mixins()

    const kaotan_sid: any = computed(() => {
      return store.getters.kaotanSid
    })
    const isPending: any = computed(() => {
      return store.getters.pending
    })
    // console.log('kaotan_sid', kaotan_sid.value)

    // gapiをload
    gapi.load('picker', () => { store.commit('loadedGapiPicker', true) })

    return {
      route,
      router,
      store,
      gapi,
      conf,
      mixins,
      kaotan_sid,
      isPending,
      // signedIn,
    }
  },
  data(): {
    // defaultLayout: string | null
    created: boolean
  } {
    return {
      // defaultLayout: 'default',
      created: false,
    }
  },
  computed: {
    layout(): boolean | string {
      if (!this.$route.name) {
        return false
      } else {
        return (this.$route.meta.layout || 'default') + '-layout'
      }
    },
    profile(): any {
      return this.store.getters.profile
    },
  },
  async created() {
    const query = this.mixins.getQuery()
    console.log('query', query)
    // TODO: onloginに到達する前にcomponentのsetup処理が走っているのでawaitさせてもよいかも
    if (query.code) {
      await this.axios
        .post(this.conf.APIHOST + '/api/signin', {
          code: query.code,
          redirect_uri: location.origin + location.pathname,
        })
        .then(async (r: any) => {
          console.log('/api/signin', r.data)
          if (r.data.kaotan_sid) {
            // document.cookie = 'kaotan_sid=' + r.data.kaotan_sid
            // vuexへ保管
            this.store.commit('setKaotanSid', r.data.kaotan_sid)
            // code類をURLから消す
            history.replaceState(null, '', location.pathname)
            // this.api('/api/islogin').then(this.onlogin)
            await this.mixins.api('/api/islogin').then((res: any) => {
              this.mixins.onlogin(res)
            })
          }
        })
    } else {
      await this.mixins.api('/api/islogin').then(async (res: any) => {
        if (Object.keys(res).length) {
          if ('poweruser' in res) {
            this.store.commit('setIsPowerUser', res.poweruser)
          }
          if ('superuser' in res) {
            this.store.commit('setIsSuperUser', res.superuser)
          }
          await this.mixins.onlogin(res)
          console.log('onlogin')
        } else {
          console.log('signinが無効')
        }
      })
    }
    this.created = true

    // 吾郎さん式
    // // OAuthのコールバック処理
    // if (p.code) {
    //   this.axios
    //     .post(this.conf.APIHOST + '/api/signin', {
    //       code: p.code,
    //       redirect_uri: location.origin + location.pathname,
    //     })
    //     .then((r: any) => {
    //       console.log(r.data)
    //       if (r.data.kaotan_sid){
    //         document.cookie = 'kaotan_sid=' + r.data.kaotan_sid
    //         // code類をURLから消す
    //         history.replaceState(null, '', location.pathname)
    //         // this.api('/api/islogin').then(this.onlogin)
    //       }
    //     })
    // } else {
    //   this.api('/api/islogin').then(this.onlogin)
    // }
  },
  methods: {
    test() {
      console.log('test')
    },
  },
})
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style lang="stylus">
//
</style>
