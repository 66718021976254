<template>
  <div class="loading">
    <div class="loader">Loading...</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'Loading',
  components: {
    //
  },
  // props: ['tree'],
  data(): {
    //
  } {
    return {
      //
    }
  },
  computed: {
    //
  },
  methods: {
    //
  },
})
</script>

<style lang="stylus" scoped>
.loading
  position fixed
  z-index 9999
  left 0
  top 0
  width 100%
  height 100%
  display grid
  align-items center
  justify-content center
  user-select none
  background-color alpha(#000, .2)
  backdrop-filter blur(2px)

  $r = 120px
  .loader,
  .loader:after
    border-radius 50%
    width  $r
    height $r
  .loader
    margin 60px auto
    position relative
    text-indent -9999em
    border-top    8px solid alpha(#fff, .1)
    border-right  8px solid alpha(#fff, .1)
    border-bottom 8px solid alpha(#fff, .1)
    border-left   8px solid #fff
    transform translateZ(0)
    animation loader 1.1s infinite linear

  @keyframes loader
    0%
      transform rotate(0deg)
    100%
      transform rotate(360deg)
</style>
