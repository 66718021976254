
import { defineComponent, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'

export default defineComponent({
  name: 'CommonHeader',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    // グローバル変数を読み込み
    const gapi: any = inject('$gapi')
    const conf: any = inject('$conf')

    const mixins = new Mixins()

    // プロジェクトを開く
    const clear = () => {
      store.commit('setProject', null)
      store.commit('setFolder', null)
      store.commit('setFolders', null)
      store.commit('setSearch', null)
      store.commit('setResult', null)
    }
    return {
      route,
      router,
      store,
      gapi,
      conf,
      mixins,
      clear,
    }
  },
  data(): {
    ui: any
  } {
    return {
      ui: {
        usernav: false,
      },
    }
  },
  computed: {
    profile(): any {
      return this.store.getters.profile
    }
  },
  watch: {
    'ui.usernav': function () {
      if (this.ui.usernav) {
        document.addEventListener('click', this.closeNav)
      } else {
        document.removeEventListener('click', this.closeNav)
      }
    },
  },
  methods: {
    // async signOut() {
    //   try {
    //     this.mixins.logout()
    //     this.store.commit('setKaotanSid', null) // 取得したkaotan_sidを破棄
    //     window.location.href = '/'
    //   } catch (error) {
    //     console.error(error)
    //   }
    // }
    onHelp() {
      window.open('https://sites.google.com/tbs.co.jp/kaotan/home', '_blank')
    },
    closeNav() {
      this.ui.usernav = false
    },
  },
})
