
import { defineComponent, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    // グローバル変数を読み込み
    const gapi: any = inject('$gapi')
    const conf: any = inject('$conf')

    const mixins = new Mixins()

    router.push('/')

    document.title = `ログイン｜KAOTAN`

    return {
      route,
      router,
      store,
      gapi,
      conf,
      mixins,
    }
  },
  data() {
    return {
      //
    }
  },

  methods: {
    async signedIn() {
      // 吾郎さん式
      let scope = this.conf.SCOPES.join(' ')
      let redirect_uri = location.origin + location.pathname
      let url = `https://accounts.google.com/o/oauth2/auth?client_id=${this.conf.CLIENT_ID}&redirect_uri=${redirect_uri}&scope=${scope}&response_type=code&access_type=offline`
      url += `&approval_prompt=force`
      setTimeout(() => {
        location.href = url
      }, 200)
    }
  },
})
