import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { store } from "./store"
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLazyLoad from 'vue3-lazyload'

// declare module '@vue3-google-oauth2'
// import GAuth from 'vue3-google-oauth2'

// Style
import '../node_modules/ress'
import '@/assets/styles/style.styl'

// Layouts
import DefaultLayout from '@/layouts/DefaultLayout.vue'

// Mixins
// import Mixins from '@/mixins/mixins'

// Conf
declare const Conf: any
// const gAuthOptions: any = {
//   clientId: Conf.CLIENT_ID,
//   scope: Conf.SCOPES.join(' '),
//   prompt: 'consent',
//   fetch_basic_profile: false,
// }
declare const gapi: any
// console.log(gapi)

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueLazyLoad, {
    // loading: () => {
    //   console.log('loading')
    // },
    // error: () => {
    //   console.log('error')
    // },
    // loaded: () => {
    //   console.log('loaded')
    // }
  })
  // .use(Mixins)
  .provide('$gapi', gapi)
  .provide('$conf', Conf)
  .component('default-layout', DefaultLayout)
  .mount('body')

// OS判定
if (navigator.platform.indexOf('Win') !== -1) {
  document.querySelector('body')?.classList.add('win')
} else {
  document.querySelector('body')?.classList.add('mac')
}
