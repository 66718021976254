<template>
  <header class="header">
    <div class="logo">
      <router-link to="/" @click="clear"><img src="/assets/img/logo.svg" alt="KAOTAN"></router-link>
    </div>
    <div class="contact">
      <div class="button" @click="onHelp">
        <div class="icon"><i class="material-icons">help_outline</i></div>
      </div>
    </div>
    <div class="user">
      <div class="name">{{ `${profile.googleuser.name}さん` }}</div>
      <div class="icon" @click.stop="ui.usernav = !ui.usernav"><img :src="profile.googleuser.picture" /></div>
    </div>
    <div class="nav" :class="{on: ui.usernav}">
      <div class="row"><div class="button" @click="mixins.signOut"><i class="material-icons">logout</i><span class="text">ログアウト</span></div></div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'

export default defineComponent({
  name: 'CommonHeader',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    // グローバル変数を読み込み
    const gapi: any = inject('$gapi')
    const conf: any = inject('$conf')

    const mixins = new Mixins()

    // プロジェクトを開く
    const clear = () => {
      store.commit('setProject', null)
      store.commit('setFolder', null)
      store.commit('setFolders', null)
      store.commit('setSearch', null)
      store.commit('setResult', null)
    }
    return {
      route,
      router,
      store,
      gapi,
      conf,
      mixins,
      clear,
    }
  },
  data(): {
    ui: any
  } {
    return {
      ui: {
        usernav: false,
      },
    }
  },
  computed: {
    profile(): any {
      return this.store.getters.profile
    }
  },
  watch: {
    'ui.usernav': function () {
      if (this.ui.usernav) {
        document.addEventListener('click', this.closeNav)
      } else {
        document.removeEventListener('click', this.closeNav)
      }
    },
  },
  methods: {
    // async signOut() {
    //   try {
    //     this.mixins.logout()
    //     this.store.commit('setKaotanSid', null) // 取得したkaotan_sidを破棄
    //     window.location.href = '/'
    //   } catch (error) {
    //     console.error(error)
    //   }
    // }
    onHelp() {
      window.open('https://sites.google.com/tbs.co.jp/kaotan/home', '_blank')
    },
    closeNav() {
      this.ui.usernav = false
    },
  },
})
</script>

<style lang="stylus" scoped>
.header
  display grid
  grid-template auto / 1fr max-content auto
  grid-gap $gap
  align-items center
  justify-content space-between
  padding 0 $gap
  background-color $color-base--light

  .logo
    width auto
    height 24px
    img
      width auto
      height 24px
      object-fit contain
  //
  .contact
    .button
      cursor pointer
      &:hover
        color $color-hover
  //
  .user
    display flex
    align-items center
    grid-gap .5rem
    .name
      font-size .7rem
    .icon
      width  36px
      height 36px
      background-color #fff
      border-radius 100%
      overflow hidden
      cursor pointer
      img
        width 100%
        height 100%
        object-fit contain
  //
  .nav
    position fixed
    z-index 9999
    right $gap
    top 50px
    background-color #fff
    border-radius 6px
    padding 0 .5em
    // min-width 160px
    box-shadow 0 0 10px alpha(#000, .5)
    // anim
    opacity 0
    transform translateY(-10px)
    pointer-events none
    transition .2s
    &.on
      opacity 1
      transform translateY(0px)
      pointer-events auto
      .button
        pointer-events auto
    .row
      padding .3em 0
      & + .row
        border-top 1px solid #eee
    .button
      display grid
      grid-template auto / auto 1fr
      grid-gap .5em
      align-items center
      color $color-text--darken
      padding .3em
      font-size .7rem
      pointer-events none
      &:hover
        background-color #eee


</style>
