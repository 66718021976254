<template>
  <div id="login" class="layout">
    <h1>
      <picture><img src="/assets/img/logo.svg" alt="KAOTAN" /></picture>
    </h1>
    <div class="button" @click="signedIn()">
      <i class="icon material-icons md-64">person</i>
      <span class="label">ログイン</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    // グローバル変数を読み込み
    const gapi: any = inject('$gapi')
    const conf: any = inject('$conf')

    const mixins = new Mixins()

    router.push('/')

    document.title = `ログイン｜KAOTAN`

    return {
      route,
      router,
      store,
      gapi,
      conf,
      mixins,
    }
  },
  data() {
    return {
      //
    }
  },

  methods: {
    async signedIn() {
      // 吾郎さん式
      let scope = this.conf.SCOPES.join(' ')
      let redirect_uri = location.origin + location.pathname
      let url = `https://accounts.google.com/o/oauth2/auth?client_id=${this.conf.CLIENT_ID}&redirect_uri=${redirect_uri}&scope=${scope}&response_type=code&access_type=offline`
      url += `&approval_prompt=force`
      setTimeout(() => {
        location.href = url
      }, 200)
    }
  },
})
</script>

<style lang="stylus" scoped>
.layout
  display grid
  grid-template auto \/ auto
  grid-gap $gap * 2
  align-items center
  align-content center
  justify-content center
  height -webkit-fill-available
  background url('/assets/img/kaotan.png') left bottom no-repeat
  h1
    width 720px
    height 160px
    picture
      width 100%
      height 100%
      display block
    img
      width 100%
      height 100%
      object-fit contain
  .button
    display grid
    align-items center
    align-content center
    justify-content center
    width  200px
    height 200px
    margin auto
    background-color alpha(#fff, .1)
    border-radius 100%
    cursor pointer
    &:hover
      background-color $color-active

    .icon
      display block
      margin auto
    .label
      font-size .8rem


</style>