<template>
  <div id="def" class="layout">
    <common-header></common-header>
    <router-view />
    <!-- <div class="loading" v-if="this.$store.getters.pending">
      <div class="loader">Loading...</div>
    </div>
    <div class="notice">
      <transition-group name="notice">
        <div class="item" v-for="(item, i) in notice" :key="i" :class="{attention: item.attention}">
          <p class="text" v-html=item.text></p>
          <div class="close" v-if="!item.duration" @click="closeNotice(item)"><i><font-awesome-icon :icon="['fas', 'times-circle']"/></i></div>
        </div>
      </transition-group>
    </div> -->
  </div>
</template>

<script>
import CommonHeader from '@/components/CommonHeader.vue'
// import CommonFooter from '@/components/CommonFooter.vue'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DefLayout',
  components: {
    'common-header': CommonHeader,
    // 'common-footer': CommonFooter,
  },
  data() {
    return {
      //
    }
  },
})
</script>

<style lang="stylus" scoped>

.layout
  display grid
  grid-template 50px calc(100% - 50px) / auto
  grid-gap 2px
  height 100%
  overflow hidden

</style>
