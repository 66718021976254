
import { defineComponent, ref, computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'Loading',
  components: {
    //
  },
  // props: ['tree'],
  data(): {
    //
  } {
    return {
      //
    }
  },
  computed: {
    //
  },
  methods: {
    //
  },
})
