import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

// ストアの型を定義する
export interface State {
  gapiStatus: any
  isSignedIn: boolean
  isPowerUser: boolean
  isSuperUser: boolean
  kaotan_sid: string | null
  profile: any | null
  project: any | null
  folder: any | null
  folders: any | null
  result: any | null
  filters: any
  sort: any
  search: any[] | null
  pending: any[]
  driveFiles: any
  selects: any
  selectedProjects: any
  selectedFolders: any
  selectedSources: any
  selectedWatchlists: any
}

export const store = createStore<State>({
  plugins: [
    createPersistedState({
      key: 'kaotan',
      paths: ['kaotan_sid'],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    createPersistedState({
      key: 'kaotan-config',
      paths: ['sort'],
      storage: window.localStorage,
    }),
  ],
  state: {
    gapiStatus: {
      picker: false,
    },
    isSignedIn: false,
    isPowerUser: false,
    isSuperUser: false,
    kaotan_sid: null,
    profile: null,
    project: null,
    folder: null,
    folders: null,
    result: null,
    filters: {
      projectQuery: {
        name: '',
      },
      sozaiQuery: {
        name: '',
        speech: '',
        cast: '',
      },
      watchlistQuery: {
        name: '',
      },
    },
    sort: {
      key: 'name', // ソートキー
      ascend: true, // true: 昇順, false: 降順
    },
    search: null,
    pending: [],
    driveFiles: [],
    selects: [],
    selectedProjects: [],
    selectedFolders: [],
    selectedSources: [],
    selectedWatchlists: [],
  },
  getters: {
    gapiStatus(state: any) {
      return state.gapiStatus
    },
    isSignedIn(state: any) {
      return state.isSignedIn
    },
    isPowerUser(state: any) {
      return state.isPowerUser
    },
    isSuperUser(state: any) {
      return state.isSuperUser
    },
    kaotanSid(state: any) {
      return state.kaotan_sid
    },
    profile(state: any) {
      return state.profile
    },
    project(state: any) {
      return state.project
    },
    folder(state: any) {
      return state.folder
    },
    folders(state: any) {
      return state.folders
    },
    result(state: any) {
      return state.result
    },
    filtersProjectQuery(state: any) {
      return state.filters.projectQuery
    },
    filtersSozaiQuery(state: any) {
      return state.filters.sozaiQuery
    },
    filtersSceneQuery(state: any) {
      return state.filters.sceneQuery
    },
    filtersWatchlistQuery(state: any) {
      return state.filters.watchlistQuery
    },
    sort(state: any) {
      return state.sort
    },
    search(state: any) {
      return state.search
    },
    pending(state: any) {
      return state.pending.length ? true : false
    },
    driveFiles(state: any) {
      return state.driveFiles
    },
    selects(state: any) {
      return state.selects
    },
    selectedProjects(state: any) {
      return state.selectedProjects
    },
    selectedFolders(state: any) {
      return state.selectedFolders
    },
    selectedSources(state: any) {
      return state.selectedSources
    },
    selectedWatchlists(state: any) {
      return state.selectedWatchlists
    },
  },
  mutations: {
    loadedGapiPicker: (state: any, status: boolean) => {
      state.gapiStatus.picker = status
    },
    setIsSignedIn: (state: any, isSignedIn: any) => {
      state.isSignedIn = isSignedIn
    },
    setIsPowerUser: (state: any, isPowerUser: any) => {
      state.isPowerUser = isPowerUser
    },
    setIsSuperUser: (state: any, isSuperUser: any) => {
      state.isSuperUser = isSuperUser
    },
    setKaotanSid: (state: any, kaotan_sid: any) => {
      state.kaotan_sid = kaotan_sid
    },
    setProfile: (state: any, profile: any) => {
      state.profile = profile
    },
    setProject: (state: any, project: any) => {
      state.project = project
    },
    setFolder: (state: any, folders: any) => {
      state.folder = folders
    },
    setFolders: (state: any, folders: any) => {
      state.folders = folders
    },
    setResult: (state: any, result: any) => {
      state.result = result
    },
    setFiltersProjectQuery: (state: any, query: any) => {
      state.filters.projectQuery = query
    },
    setFiltersSozaiQuery: (state: any, query: any) => {
      state.filters.sozaiQuery = query
    },
    setFiltersSceneQuery: (state: any, query: any) => {
      state.filters.sceneQuery = query
    },
    setFiltersWatchlistQuery: (state: any, query: any) => {
      state.filters.watchlistQuery = query
    },
    setSort(state: any, sort: any[]) {
      state.sort = sort
    },
    setSearch(state: any, search: any[]) {
      state.search = search
    },
    setPending(state: any, pending: any[]) {
      if (pending) {
        state.pending.push(true)
      } else {
        state.pending.shift()
      }
    },
    clearPending(state: any) {
      state.pending = []
    },
    setDriveFiles(state: any, files) {
      state.driveFiles = files
    },
    setSelects(state: any, selects: any) {
      state.selects = selects
    },
    clearSelects(state: any) {
      state.selectedProjects = []
      state.selectedFolders = []
      state.selectedSources = []
    },
    setSelectedProjects(state: any, selectedProjects: any) {
      state.selectedProjects = selectedProjects
    },
    setSelectedFolders(state: any, selectedFolders: any) {
      state.selectedFolders = selectedFolders
    },
    setSelectedSources(state: any, selectedSources: any) {
      state.selectedSources = selectedSources
    },
    setSelectedWatchlists(state: any, selectedWatchlists: any) {
      state.selectedWatchlists = selectedWatchlists
    },
  },
  actions: {},
  modules: {},
})
